import React from 'react';
import { Helmet } from 'react-helmet';

// Inject "https://donorbox.org/widget.js" into the page via react-helmet and render the donorbox iframe
export const GoogleCalender = ({ id }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  return (
    <div>
      <iframe
        src={`https://calendar.google.com/calendar/embed?src=${id}&mode=${
          isMobile ? 'AGENDA' : 'MONTH'
        }`}
        height="700px"
        width="100%"
        style={{ maxWidth: '80%', margin: 'auto' }}
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};
