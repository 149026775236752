import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { GoogleCalender } from '../components/google_calendar';
import { SuperButton } from '../components/layout/buttons';
import SfIllustrationWhite from '../images/core/sf-illustration-white.svg';
import GoogleIcon from '../images/icons/icon-google-white.svg';
import AppleIcon from '../images/icons/icon-apple-white.svg';

const CalenderPage = (props) => (
  <Layout>
    <SEO
      title="Calendar of Fun Events in SF, brought to you by GrowSF"
      description="Subscribe to find fun things to do in the city!"
      pathname={props.location.pathname}
    />
    <div className="pt-12 bg-white">
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8">
        <div className="text-center py-12">
          <h2 className="text-base text-brand-blue-4 font-semibold tracking-wide uppercase">
            GrowSF Events
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-6xl">
            GrowSF Events Calendar
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-700 mx-auto">
            Subscribe to our events calendar so you always know what's going on in the city.
          </p>
        </div>
      </div>

      {/* Begin Calendar subscribe */}
      <div className="sm:mx-auto bg-brand-blue-1 p-5 sm:p-10 relative overflow-clip mx-auto mb-4 sm:mb-0 px-5 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="z-10 relative flex flex-col md:flex-row md:justify-between items-center w-full">
          <div>
            <h2 className="text-lg md:text-xl  font-bold text-gray-900 w-full">
              Subscribe to GrowSF's events calendar
            </h2>
          </div>
          <div className="flex flex-col w-full space-y-6 mt-6 items-center justify-center sm:w-1/2 sm:space-y-0 sm:flex-row sm:space-x-6 sm:mt-0">
            <SuperButton
              className="flex items-center space-x-1 justify-center"
              color="blue"
              size="md"
              to="https://calendar.google.com/calendar/embed?src=c_k1le2a1m2sfe307rhrp80e2ag4%40group.calendar.google.com&ctz=America%2FLos_Angeles"
            >
              <img src={GoogleIcon} className="w-5 h-5" />
              <span className="text-md">Google</span>
            </SuperButton>
            <SuperButton
              className="flex items-center space-x-1 justify-center"
              color="blue"
              size="md"
              to="https://calendar.google.com/calendar/ical/c_k1le2a1m2sfe307rhrp80e2ag4%40group.calendar.google.com/public/basic.ics"
            >
              <div className="w-5 h-5 flex items-center">
                <img src={AppleIcon} className="w-[16.28px] h-[20px] align-middle" />
              </div>
              <span className="text-md">Apple</span>
            </SuperButton>
          </div>
        </div>
        <img
          className="z-0 scale-75 absolute -bottom-20 -right-20"
          src={SfIllustrationWhite}
          alt="Line art illustration of San Francisco"
        />
      </div>
      {/* End Secondary Email Signup */}

      <div className="relative mx-auto max-w-md px-5 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-10">
        <GoogleCalender id="c_k1le2a1m2sfe307rhrp80e2ag4%40group.calendar.google.com" />
      </div>

      <div className="mx-4 sm:mx-auto bg-brand-blue-1 p-5 sm:p-10 relative overflow-clip mt-10 mx-auto px-5 sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-4 max-w-2xl text-xl text-gray-700 mx-auto">
          Do you know a fun event that we're missing?{' '}
          <OutboundLink href="mailto:contact@growsf.org" className="underline">
            Email Us
          </OutboundLink>
        </p>
      </div>
    </div>
  </Layout>
);
export default CalenderPage;
